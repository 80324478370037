var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"p-3",class:{ 'register-box': !_vm.isMobile }},[_c('b-row',_vm._l((_vm.dynamicField),function(field,index){return _c('b-col',{key:'dynamic-' + index,attrs:{"md":"6"}},[(field.field_type_id == 1)?_c('div',[(!_vm.isLoading)?_c('InputText',{staticClass:"margin-inputf-regular mb-2",attrs:{"textFloat":field.field_language[0].name,"placeholder":field.field_language[0].name,"type":_vm.checkFieldConditon(field),"name":"firstname","maxLength":_vm.checkMaxMin(field.field_conditions, 2),"min":_vm.checkMaxMin(field.field_conditions, 3),"max":_vm.checkMaxMin(field.field_conditions, 4),"disabled":field.field_profile_type_id == 10,"index":index},on:{"onDataChange":_vm.isMaxMin,"onkeypress":_vm.isNumber},model:{value:(field[_vm.keyValue]),callback:function ($$v) {_vm.$set(field, _vm.keyValue, $$v)},expression:"field[keyValue]"}}):_vm._e()],1):(
            field.field_type_id == 2 && field.field_profile_type_id != 17
          )?_c('div',[_c('div',{staticClass:"input-custom mt-2"},[_c('label',{staticClass:"title"},[_vm._v(" "+_vm._s(field.field_language[0].name)+" ")]),_c('b-form-checkbox-group',{staticClass:"ml-2 mb-2",attrs:{"id":`${field.id}`,"size":"lg","options":field.field_choices.map((x) => ({
                  text: x.name,
                  value: x.name,
                })),"name":"text","value":field[_vm.keyValue]},model:{value:(field[_vm.keyValue]),callback:function ($$v) {_vm.$set(field, _vm.keyValue, $$v)},expression:"field[keyValue]"}})],1)]):(field.field_profile_type_id == 17)?_c('div',[_c('div',{staticClass:"input-custom"},[_c('b-form-checkbox-group',{staticClass:"ml-2 mb-2",attrs:{"size":"lg","options":[{ name: 'Set to default', value: 1 }],"name":"name","value":field[_vm.keyValue]},model:{value:(field[_vm.keyValue]),callback:function ($$v) {_vm.$set(field, _vm.keyValue, $$v)},expression:"field[keyValue]"}})],1)]):(field.field_profile_type_id == 17)?_c('div',[_c('div',{staticClass:"input-custom"},[_c('b-form-checkbox-group',{staticClass:"ml-2 mb-2",attrs:{"size":"lg","options":[{ name: 'Set to default', value: 1 }],"name":"name","value":field[_vm.keyValue]},model:{value:(field[_vm.keyValue]),callback:function ($$v) {_vm.$set(field, _vm.keyValue, $$v)},expression:"field[keyValue]"}})],1)]):(field.field_type_id == 4)?_c('div',[_c('InputSelect',{staticClass:"f-regular mb-2",attrs:{"title":field.field_language[0].name,"options":[
              {
                text: field.field_language[0].name,
                value: '',
                disabled: true,
              },
              ...field.field_choices.map((x) => ({
                text: x.name,
                value: x.name,
              })),
            ]},on:{"onDataChange":(val) =>
                    field[_vm.keyValue] =
                        (val),},model:{value:(field[_vm.keyValue]),callback:function ($$v) {_vm.$set(field, _vm.keyValue, $$v)},expression:"field[keyValue]"}})],1):(field.field_type_id == 3)?_c('div',[_c('InputRadioGroup',{staticClass:"mt-2 mb-2",attrs:{"textFloat":field.field_language[0].name,"options":field.field_choices.map((x) => ({
                text: x.name,
                value: x.name,
              }))},on:{"onDataChange":(val) => (field[_vm.keyValue] = val)},model:{value:(field[_vm.keyValue]),callback:function ($$v) {_vm.$set(field, _vm.keyValue, $$v)},expression:"field[keyValue]"}})],1):(field.field_type_id == 7)?_c('div',[_c('datetime',{staticClass:"datetime-h mb-2",class:['date-picker-input date-filter'],attrs:{"type":"date","placeholder":field.field_language[0].name,"format":'dd/MM/yyyy',"value-zone":"Asia/Bangkok","maxDatetime":_vm.now},on:{"input":(val) => field[_vm.keyValue] = (val),},model:{value:(field[_vm.keyValue]),callback:function ($$v) {_vm.$set(field, _vm.keyValue, $$v)},expression:"field[keyValue]"}},[_c('label',{staticClass:"title",attrs:{"slot":"before","for":"startDate"},slot:"before"},[_vm._v(_vm._s(field.field_language[0].name)+" ")])])],1):(field.field_type_id == 5)?_c('div',[_c('datetime',{staticClass:"datetime-h mb-2",class:['date-picker-input date-filter'],attrs:{"type":"datetime","placeholder":field.field_language[0].name,"format":'dd/MM/yyyy HH:mm',"value-zone":"Asia/Bangkok","maxDatetime":_vm.now},on:{"input":(val) => field[_vm.keyValue] = (val),},model:{value:(field[_vm.keyValue]),callback:function ($$v) {_vm.$set(field, _vm.keyValue, $$v)},expression:"field[keyValue]"}},[_c('label',{staticClass:"title",attrs:{"slot":"before","for":"startDate"},slot:"before"},[_vm._v(_vm._s(field.field_language[0].name)+" ")])])],1):(field.field_type_id == 6)?_c('div',{staticClass:"input-custom"},[_c('InputSelect',{staticClass:"f-regular mb-2",attrs:{"title":field.field_language[0].name,"text":field.field_language[0].name,"name":field.field_language[0].name,"options":_vm.branchList.map((x) => ({ value: x.id, text: x.name })),"isRequired":field[_vm.keyRequired]},on:{"onDataChange":(val) =>
                    field[_vm.keyValue] =
                        (val),},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v("-- "+_vm._s(field.field_language[0].name)+" --")])]},proxy:true}],null,true),model:{value:(field[_vm.keyValue]),callback:function ($$v) {_vm.$set(field, _vm.keyValue, $$v)},expression:"field[keyValue]"}})],1):(field.field_type_id == 10)?_c('div',[_c('h5',{staticClass:"mb-2"},[_vm._v(_vm._s(field.field_language[0].name))])]):(field.field_type_id == 11)?_c('div',[_c('h5',{staticClass:"mb-2"},[_vm._v(_vm._s(field.field_language[0].name))])]):(field.field_type_id == 8)?_c('div',[_c('label',{staticClass:"title"},[_vm._v(" "+_vm._s(field.field_language[0].name)+" ")]),_c('InputUploadImage',{staticClass:"mb-2",attrs:{"label":field.field_language[0].name,"img":field[_vm.keyValue]},on:{"setFileName":(val) => _vm.setImageName(val, field.field_profile_type_id)},model:{value:(field[_vm.keyValue]),callback:function ($$v) {_vm.$set(field, _vm.keyValue, $$v)},expression:"field[keyValue]"}})],1):(field.field_type_id == 12)?_c('div',[_c('InputTextArea',{attrs:{"placeholder":field.field_language[0].name,"textFloat":field.field_language[0].name,"name":"text","rows":"3"},model:{value:(field[_vm.keyValue]),callback:function ($$v) {_vm.$set(field, _vm.keyValue, $$v)},expression:"field[keyValue]"}})],1):_vm._e()])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }